@import "vars";

.sign_in {
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-4;

    > section {
      max-width: 500px;
      width: 100%;
    }

    &:before {
      display: none;
    }

    .link__arrow {
      display: flex;
      align-self: center;
      justify-content: flex-end;

      > a[href]:before {
        width: 38px;
      }
    }

    .form-new-password {
      background: $color-2;
      padding: 10px;
      position: absolute;
      bottom: -145px;
      right: 0;

      &:after {
        content: "";
        position: absolute;
        top: -10px;
        right: 65px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 7.5px 10px 7.5px;
        border-color: transparent transparent $color-2 transparent;
      }

      p {
        margin-bottom: 20px;
      }
  
      .form-input-element {
        background: $color-4;
        border: 0;
        outline: 0;
      }
      
      .form-input {
        flex: 0 0 68%;
      }
      
      .form-button {
        flex: 0 0 30%;
        background: $color-2;
      }
    }
    
    .form-sign-in {
      .button-row {
        @include max-width($tablet) {
          flex-direction: column;
        }
  
        .link__arrow {
          @include max-width($tablet) {
            margin-top: 20px;
            align-self: flex-start;
          }
        }
      }
    }
  }
}