* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

a[href] {
  text-decoration: none;
  color: inherit;
  display: inline-block;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

img {
  max-width: 100%;
}
