@import "vars";

.login {
  .tile:nth-child(2) {
    box-shadow: 0 0 15px $color-1;
    clip-path: inset(-15px 0 0 0);
    z-index: 1;

    a[href] {
      span {
        display: none;
      }

      svg {
        display: block;
        max-width: 300%;
        transform: translate(-50%, -42%);
      }
    }
  }

  .content {
    color: $color-5;

    &:before {
      display: none;
    }
  }

  article {
    background: url("../images/background_1.svg") center top -200px no-repeat $color-4;
    background-size: 500px auto;
  
    @include max-width($tablet) {
      background-size: 100%;
    }
  }
}