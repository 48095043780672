@font-face {
  font-family: 'matryoshka';
  src: url('../fonts/volcano_type_-_matryoshkam-webfont.woff2') format('woff2'),
  url('../fonts/volcano_type_-_matryoshkam-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

//@font-face {
//  font-family: 'matryoshka';
//  src: url('../fonts/volcano_type_-_matryoshkapregnant-webfont.woff2') format('woff2'),
//  url('../fonts/volcano_type_-_matryoshkapregnant-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}

@font-face {
  font-family: 'matryoshka';
  src: url('../fonts/volcano_type_-_matryoshkas-webfont.woff2') format('woff2'),
  url('../fonts/volcano_type_-_matryoshkas-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

//@font-face {
//  font-family: 'matryoshka';
//  src: url('volcano_type_-_matryoshkaxl-webfont.woff2') format('woff2'),
//  url('volcano_type_-_matryoshkaxl-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}

@font-face {
  font-family: 'matryoshka';
  src: url('../fonts/volcano_type_-_matryoshkaxs-webfont.woff2') format('woff2'),
  url('../fonts/volcano_type_-_matryoshkaxs-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

//@font-face {
//  font-family: 'matryoshka';
//  src: url('volcano_type_-_matryoshkaxxl-webfont.woff2') format('woff2'),
//  url('volcano_type_-_matryoshkaxxl-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}

//@font-face {
//  font-family: 'matryoshka';
//  src: url('volcano_type_-_matryoshkaxxs-webfont.woff2') format('woff2'),
//  url('volcano_type_-_matryoshkaxxs-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
//}

@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto-regular-webfont.woff2') format('woff2'),
  url('../fonts/roboto-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto-black-webfont.woff2') format('woff2'),
  url('../fonts/roboto-black-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'roboto';
  src: url('../fonts/roboto-bold-webfont.woff2') format('woff2'),
  url('../fonts/roboto-bold-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/*@font-face {
  font-family: 'robotobold';
  src: url('roboto-bold-webfont.woff2') format('woff2'),
  url('roboto-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style../fonts/: normal;
  font-display: swap;
}

@font-face {
  font-family: 'robotolight';
  src: url('roboto-light-webfont.woff2') format('woff2'),
  url('roboto-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'robotomedium';
  src: url('roboto-medium-webfont.woff2') format('woff2'),
  url('roboto-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'robotothin';
  src: url('roboto-thin-webfont.woff2') format('woff2'),
  url('roboto-thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}*/