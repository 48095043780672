@import "vars";

.students-list {
  @include max-width($tablet) {
    padding: 0 20px;
  }

  &__header {
    text-align: center;
    position: relative;

    .link__arrow {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);

      @include max-width($tablet) {
        top: 100%;
        right: -25px;
      }
    }
  }

  .birth {
    .form-input {
      .form-input-element {
        min-width: 60px;
      }
      &:last-child {
        .form-input-element {
          min-width: 80px;
        }
      }
    }
  }

  .grid-8 {
    grid-template-columns: 80px 130px 130px 230px 100px auto 100px 140px;
    grid-gap: 15px;

    > div {
      &:first-child {
        width: 100px;

        svg {
          width: 30px;
          height: auto;

          &.save {
            width: 40px;
            position: relative;
            top: 5px;
          }

          &:first-child {
            margin-right: 10px;
          }
        }
      }
    }
  }

  .hint-footer,
  .form-footer {
    position: relative;
  }

  .hint-footer {
    text-align: left;
    justify-content: flex-start;
    display: flex;
    align-items: center;

    .link__arrow {
      > a[href] {
        &:before {
          background: url("../images/IMG_Pfeil_Klein-1.svg") center center
            no-repeat;
          margin-right: 0 !important;
        }
      }
    }
  }

  .overlay {
    left: -65px;
    bottom: 50px;
    right: auto;
    top: auto;
    max-width: 300px;
    text-align: center;

    p {
      margin-bottom: 30px;
    }

    &:after {
      bottom: -10px;
      top: auto;
      right: auto;
      left: 50%;
      border-width: 10px 7.5px 0 7.5px;
      border-color: $color-2 transparent transparent transparent;
    }
  }

  .form-footer {
    .overlay {
      left: 0;
      right: 0;
      margin: 0 auto;
      max-width: 350px;

      > p {
        margin: 0 0 10px 0;
      }

      &:after {
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
  }

  &__footer {
    display: flex;

    > div {
      margin-top: 20px;
      padding-top: 20px;
      flex: 1 1 33%;
      text-align: center;

      .link__arrow {
        float: right;
      }
    }
  }

  &__student {
    &--reset-fields,
    &--add,
    &--delete,
    &--edit {
      position: relative;
      float: left;

      .overlay {
        left: 0;
        right: auto;
        top: 50px;
        bottom: auto;

        &:after {
          top: -10px;
          bottom: auto;
          right: auto;
          left: 7px;
          border-width: 0 7.5px 10px 7.5px;
          border-color: transparent transparent $color-2 transparent;
        }

        > p {
          margin: 0 0 10px 0;
        }
      }
    }
  }

  .close {
    svg {
      width: 25px !important;
      top: -2px;
      position: relative;
    }
  }

  .edit {
    svg {
      position: relative;
      top: 5px;
    }
  }

  .table {
    &__header {
      @include max-width($tablet) {
        display: none;
      }
    }

    &__row {
      @include max-width($tablet) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &__content {
      .table__row {
        @include max-width($tablet) {
          grid-gap: 5px;
        }

        .label_mobile {
          display: none;

          @include max-width($tablet) {
            display: inline-block;
            margin-right: 10px;
            font-weight: 500;
          }
        }

        > div {
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8) {
            @include max-width($tablet) {
              display: none;
            }
          }
        }
      }
    }
  }
}
