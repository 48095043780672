.unterstuetzungsbedarf-editable {
  display: inline-grid;
  grid-template-columns: 1fr auto 1fr;
  grid-template-areas: ". check .";
  align-items: center;
  width: 100%;
  margin-top: 20px;

  > .form-input {
    grid-area: check;
    white-space: nowrap;

    > .form-input-element {
      height: 30px;
      width: 30px;
      margin-right: 10px;

      &:checked::after {
        height: 80%;
        width: 80%;
        top: unset;
      }
    }

    > label {
      font-size: 16px;
      position: initial;
    }
  }
}

.unterstuetzungsbedarf {
  display: grid;
  grid-template-columns: auto auto;
  align-self: center;

  > .unterstuetzungsbedarf-icon {
    border-radius: 50%;
    background-color: #fee354;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    font-weight: bold;
    margin-right: 5px;
  }

  > .unterstuetzungsbedarf-text {
    border-bottom: 2px solid #fee354;
    font-style: italic;
  }
}
