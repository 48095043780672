@import "vars";

.tiles {
  display: block;
  height: calc(100vh - #{$header-height});
  width: 100%;
  position: fixed;
  top: $header-height;
  max-width: $max-width;

  .tile {
    position: absolute;
    width: 50px;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font: 27px/37px "matryoshka", Arial, sans-serif;
    color: $color-5;
    overflow: hidden;
    transition: width .3s ease-out;

    svg {
      fill: $color-5;
      stroke: $color-5;
    }

    &:nth-child(1) {
      background: $color-2;
      top: 0;
      left: 0;

      a > span {
        transform-origin: top left;
        transform: rotate(-90deg);
        left: -11px;
        top: 100%;
      }
    }

    &:nth-child(2) {
      background: $color-4;
      bottom: 0;
      left: 0;

      a > span {
        transform-origin: top left;
        transform: rotate(-90deg);
        left: -11px;
        top: 100%;
      }
    }

    &:nth-child(3) {
      background: $color-3;
      right: 0;
      top: 0;

      a > span {
        transform: rotate(90deg);
        transform-origin: bottom left;
        left: calc(100% - 23px);
        top: -40px;
      }
    }

    &:nth-child(4) {
      background: $color-5;
      color: $color-4;
      right: 0;
      bottom: 0;

      a > span {
        transform: rotate(90deg);
        transform-origin: bottom left;
        left: calc(100% - 23px);
        top: -40px;
      }

      svg {
        fill: $color-4;
      }
    }

    a[href] {
      display: block;
      width: 100%;
      height: 100%;
      padding: 20px;
      position: relative;
      font: inherit;

      &:hover {
        color: inherit;
      }

      span {
        display: inline-block;
        position: relative;
        color: inherit !important;
      }

      svg {
        position: absolute;
        top: 0;
        bottom: auto;
        left: 50%;
        max-width: 100%;
        transform: translate(-50%, -50%);
        display: none;

        @include max-width($tablet) {
          max-width: 75%;
          transform: translate(-50%, -47%);
        }

        .home & {
          top: 50%;
          bottom: auto;
          left: 50%;
          display: block;
        }
      }
    }

    .home & {
      width: 50%;

      a {
        span {
          width: auto;
          transform: none;
          left: auto;
          right: auto;
          top: auto;
          bottom: auto;
          font-size: 35px;
          line-height: 42px;
          text-transform: uppercase;

          @include max-width($tablet) {
            font-size: 22px;
            line-height: 37px;
          }
        }
      }
    }
  }

  @include max-width($tablet) {
    .tile {
      position: relative;
      height: 25%;
      width: 0;
      opacity: 0;
      transition: opacity 1s ease-out;

      .home & {
        width: 100%;
        opacity: 1;
      }
    }
  }
}