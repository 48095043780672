@import "vars";

.class-detail {
  font-family: "roboto", Arial, sans-serif;

  @include max-width($tablet) {
    padding: 0 20px;
  }

  .grid-4 {
    gap: 20px;
    position: relative;

    .class-detail__block:last-child {
      .overlay {
        right: 30px;
        left: auto;

        &:after {
          left: auto;
          right: 10px;
        }
      }
    }

    @include max-width($tablet) {
      grid-template-columns: repeat(1, 1fr);
    }

    & + .grid-4 {
      margin-top: 20px;

      @include max-width($tablet) {
        margin-top: 60px;
      }
    }
  }

  .class-detail__block {
    padding: 10px;
    min-height: 180px;
    border: 4px solid transparent;
    font-size: 18px;
    position: relative;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr;

    &--A_1,
    &--B_1 {
      border-color: $color-10;
    }

    &--A_2,
    &--B_2 {
      border-color: $color-11;
    }

    &--A_3,
    &--B_3 {
      border-color: $color-12;
    }

    &--A_4,
    &--B_4 {
      border-color: $color-13;
    }
  }

  .class-detail__label {
    font-size: 14px;
    font-weight: bold;
    position: absolute;
    top: -40px;
    left: 0;
    width: 100%;
    display: flex;
    font: 24px/28px "matryoshka", Arial, sans-serif;

    > * {
      white-space: nowrap;

      &:nth-child(1) {
        margin-right: 20px;
      }

      &:nth-child(2) {
        flex: 1 1 100%;
      }
    }
  }

  .class-detail__row {
    font-size: 22px;
    font-weight: bold;
    position: absolute;
    left: -25px;
    top: 50%;
    transform: translateY(-50%);

    @include max-width($tablet) {
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .class-detail__content {
    display: flex;
    margin-bottom: 15px;

    :nth-child(2) {
      word-wrap: anywhere;
    }

    > * {
      &:nth-child(1) {
        margin-right: 10px;
      }

      &:nth-child(2) {
        flex: 1 1 100%;
      }
    }
  }

  .class-detail__header {
    text-align: center;
    margin-bottom: 30px;

    h1,
    h2 {
      font-family: "roboto", Arial, sans-serif;
    }

    h1 {
      font-size: 32px;
      line-height: 42px;
      font-weight: 500;
    }

    h2 {
      margin: 0 0 5px 0;
      text-transform: none;
      font-size: 24px;
      line-height: 36px;
    }
  }

  .class-detail__footer {
    .form-button + .form-button {
      margin-left: 30px;
    }
  }

  .class-detail__booking {
    display: flex;
    text-align: left;
    padding: 30px 0 40px 0;
    align-items: center;
    justify-content: space-between;

    @include max-width($tablet) {
      padding: 10px 0;
    }

    > div {
      display: flex;
      align-items: center;
    }

    > * + * {
      margin-left: 15px;
    }

    p {
      margin-bottom: 0;
    }

    &--edit,
    &--delete {
      position: relative;
      margin-right: 10px;
    }
  }

  .class-detail__link {
    position: relative;
    display: inline-block;

    > a[href] {
      display: flex;
      gap: 0;
      flex-direction: row;
      align-items: center;
      font-weight: 500;
      text-decoration: none !important;
      transition: color 0.3s ease-out;

      &:before {
        content: "";
        background: url("../images/IMG_Pfeil_Klein.svg") center center no-repeat;
        background-size: 100% auto;
        display: inline-block;
        width: 15px;
        height: 20px;
        margin-right: 10px;
      }
    }
  }

  .overlay {
    left: 0;
    top: 50px;
    right: auto;
    bottom: auto;
    min-width: 400px;
    text-align: center;

    &.deletePupil {
      top: 72px;
    }

    p {
      margin-bottom: 30px;
    }

    &:after {
      top: -10px;
      bottom: auto;
      right: auto;
      left: 10px;
      border-width: 0 7.5px 10px 7.5px;
      border-color: transparent transparent $color-2 transparent;
    }
  }

  .lds-dual-ring:after {
    border: 6px solid $color-1;
    border-color: $color-1 transparent $color-1 transparent;
  }

  .overlay .grid-2 {
    > div {
      width: 300px;

      + div {
        border-left: 1px solid $color-1;
        padding-left: 30px;
      }
    }
  }

  &__teacher {
    display: block;
    flex-direction: column;
    align-items: flex-start !important;

    &:hover {
      cursor: pointer;
    }

    &--headline {
      font-weight: 500;
      display: block;
    }

    &--container {
      position: relative;

      .overlay {
        left: auto;
        right: 0;
        text-align: left;
        top: 60px;
        padding: 20px;

        &:after {
          left: auto;
          right: 40px;
        }

        > div {
          display: flex;
          margin-bottom: 10px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }

        a[href] {
          &:hover {
            color: $color-1;
            text-decoration: none;
          }
        }

        p {
          margin-bottom: 0;
          display: inline-block;

          &:first-of-type {
            flex: 0 0 30%;
          }

          &:last-of-type {
            flex: 0 0 70%;
          }
        }

        .teacher-editor {
          display: grid;
          grid-template-columns: 1fr auto auto;
          grid-gap: 10px;
          width: 100%;

          .close > svg {
            height: 20px;
            width: 20px;
          }

          .submit > svg {
            margin-top: 5px;
            height: 30px;
            width: 30px;
          }
        }
      }
    }
  }

  &__messages {
    border-top: 1px solid $color-3;
    padding-top: 50px;
    margin-top: 50px;
  }
}
