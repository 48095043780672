@import "vars";

.faq {
  .tile:nth-child(4) {
    box-shadow: 0 0 15px $color-1;
    clip-path: inset(-15px 0 0 0);
    z-index: 1;

    a[href] {
      span {
        display: none;
      }

      svg {
        display: block;
        max-width: 300%;
        transform: translate(-50%, -42%);
      }
    }
  }

  .content {
    background: $color-5;
    color: $color-4;

    @include max-width($tablet) {
      .content__symbol svg {
        left: -108px;
        top: -180px;
      }
    }

    &:before {
      display: none;
    }
  }

  .question,
  .answer {
    position: relative;
    padding-left: 50px;
    font-size: 15px;
    line-height: 26px;
    text-shadow: none;

    &:before {
      font-size: 55px;
      left: 0;
      position: absolute;
      line-height: 55px;
      top: 7px;
      font-family: matryoshka, Arial, sans-serif;
      font-weight: 400;
    }
  }

  .answer {
    padding-top: 20px;

    .container {
      margin: 0;
    }
  }

  .show,
  .collapsing {
    .answer {
      &:before {
        content: "A";
      }
    }
  }

  .question {
    font-family: "roboto", Arial, sans-serif;
    position: relative;
    font-weight: 500;
    padding-left: 50px;
    padding-right: 50px;
    text-transform: none;

    &:before {
      top: 50%;
      margin-top: -25px;
    }

    &:after {
      content: '';
      background: url('../images/chevron_down.svg') no-repeat;
      right: 0;
      top: 50%;
      margin-top: -4px;
      position: absolute;
      width: 17px;
      height: 9px;
    }

    &:hover {
      cursor: pointer;
    }

    &.open {
      &:before {
        content: "F";
      }

      &:after {
        background: url('../images/chevron_up.svg') no-repeat;
      }
    }
  }

  &-element {
    border-top: 1px solid $color-6;
    padding: 20px 0;
  }
}