@import "vars";

.form-wrapper {
  position: relative;
}

.form {
  font: 16px "roboto", Arial, sans-serif;
  display: flex;
  flex-direction: column;
  margin: 0 auto 10px auto;

  h2 {
    margin-bottom: 10px;
    margin-top: 30px;
  }

  .form-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;
    position: relative;
    margin: 0 0 10px 0;

    > * {
      flex: 1 1 33%;

      & + * {
        margin-left: 10px;
      }
    }

    &.button-row {
      margin-top: 10px;
    }
  }

  .form-input {
    position: relative;
  }

  .form-input-element {
    font: 16px "roboto", Arial, sans-serif;
    appearance: none;
    background: $color-3;
    border: 2px solid $color-3;
    color: $color-1;
    height: 40px;
    padding: 0 10px;
    width: 100%;
    font-weight: 500;
    transition: outline-color 0.3s ease-out;
    border-radius: 0;

    &:active,
    &:focus {
      border: 2px solid $color-5;
      outline: none;
    }

    &[type="checkbox"] {
      cursor: pointer;
      width: 40px;
      height: 40px;
      background: unset;
      border: 2px solid $color-2;

      &:active,
      &:focus {
        outline: none;
      }

      &:checked:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 5px;
        background: url("../images/IMG_Check_black.svg");
        background-size: contain;
        background-origin: content-box;
        background-repeat: no-repeat;
      }

      + .form-label {
        cursor: pointer;
        pointer-events: auto;
        color: $color-5;
        left: 50px;
      }
    }
  }

  .form-label {
    font-weight: 400;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    color: $color-7;
    pointer-events: none;

    @include max-width($tablet) {
      font-size: 12px;
    }
  }

  .form-input-error {
    position: absolute;
    background: $color-2;
    color: $color-1;
    display: flex;
    height: 45px;
    padding: 0 15px;
    align-items: center;
    bottom: 50px;
    left: 0;
    min-width: 55px;
    margin: 0;
    z-index: 1;

    &:after {
      content: "";
      position: absolute;
      bottom: -10px;
      left: 20px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 7.5px 0 7.5px;
      border-color: $color-2 transparent transparent transparent;
    }
  }

  .button-row .form-input-error {
    top: 50px;
    bottom: auto;
    padding: 15px;
    margin: 0;
    height: auto;
    min-height: 40px;

    &:after {
      top: -10px;
      border-width: 0 7.5px 10px 7.5px;
      border-color: transparent transparent $color-2 transparent;
    }
  }

  .form-input-add,
  .form-input-remove {
    appearance: none;
    background: none;
    border: none;
    position: absolute;
    right: -40px;
    height: 40px;
    width: 40px;
    text-align: center;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .form-input-search {
    appearance: none;
    background: none;
    border: none;
    width: 40px;
    text-align: center;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 40px;
  }

  .form-button {
    flex: 0 0 50%;
  }

  .form-select-arrow {
    position: absolute;
    right: 10px;
    top: 25px;

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 8px solid $color-1;
    }
  }

  .form-select-element {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='36' viewBox='0 0 24 24' width='36' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 2px;
  }

  .react-autosuggest__container {
    position: relative;

    .react-autosuggest__suggestions-container {
      position: absolute;
      top: calc(2.5em + 3px);
      left: 0;
      right: 0;
      background: $color-3;
      color: $color-1;
      font: 16px "roboto", Arial, sans-serif;
      z-index: 1;
      max-height: 200px;
      overflow-y: auto;
    }

    .react-autosuggest__suggestions-list {
      margin: 0;
      padding: 0;
    }

    .react-autosuggest__suggestion {
      margin: 0;
      padding: 10px;
      transition: color 0.3s ease-out;
    }

    .react-autosuggest__suggestion--highlighted {
      background: $color-7;
      color: $color-2;
    }
  }

  &-full {
    width: 100%;
  }

  .form-footer {
    margin-top: 20px;
    padding-top: 20px;
    text-align: center;
    border-top: 1px solid $color-3;
  }
}

.form-button {
  appearance: none;
  border: 2px solid $color-1;
  color: $color-1;
  flex: 0 1 50%;
  height: 2.5em;
  transition: color 0.3s ease-out;
  text-transform: uppercase;
  font-weight: 500 !important;
  background: $color-4;
  padding: 7px 30px;
  white-space: nowrap;
  text-decoration: none !important;
  font-family: Roboto, sans-serif;
  line-height: 20px;

  &:disabled {
    border-color: $color-3;
    color: $color-3;
  }
}

.table__search {
  .form-label {
    @include max-width($tablet) {
      left: 50px;
    }
  }

  @media screen and (min-width: $laptop) {
    .form-row {
      margin: 0 auto;
      max-width: 750px;

      .form-label {
        margin-left: 50px;
      }

      .react-autosuggest__container {
        width: 750px;

        .react-autosuggest__suggestions-container {
          z-index: 99999;

          .react-autosuggest__suggestion {
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
