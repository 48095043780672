@import "~bootstrap/scss/bootstrap";
@import "vars";
@import "fonts";

body {
  background: url("../images/body_background.png") fixed center center;
}

main {
  font: 15px/26px "matryoshka", Arial, sans-serif;
  color: $color-5;
  background: $color-4;
  max-width: $desktop;
  margin: 0 auto;
}

header {
  background: $color-1;
  color: $color-4;
  height: $header-height;
  padding: 0 20px 0 0;
  transition: height 0.3s ease-out;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;

  .header__inner {
    max-width: $max-width;
    margin: 0 auto;
    display: flex;
    align-items: center;
    position: relative;

    @include max-width($tablet) {
    }
  }

  .logo {
    flex: 1 1 auto;

    img {
      height: 60px;
      transition: height 0.3s ease-out;
    }
  }

  @include min-width($tablet) {
    padding-left: 20px;

    &:hover {
      height: 75px;

      .logo img {
        height: 75px;
      }

      .top-nav a {
        transform: scale(1.25);
      }
    }
  }
}

footer {
  position: absolute;
  bottom: 0;
  right: 0;

  ul {
    padding: 12px 60px;

    @include max-width($tablet) {
      padding: 12px 30px;
    }

    li {
      display: inline-block;

      & + li {
        margin-left: 40px;
      }
    }
  }
}

.main {
  min-height: calc(100vh - #{$header-height});
  position: relative;
}

/** fonts */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "matryoshka", Arial, sans-serif;
  text-transform: uppercase;
}

h1 {
  font-size: 55px;
  line-height: 65px;
  margin-bottom: 25px;
  font-weight: 400;

  @include max-width($tablet) {
    font-size: 30px;
    line-height: 34px;
  }
}

h2 {
  font-size: 28px;
  line-height: 37px;
  margin-bottom: 20px;
  font-weight: 400;

  @include max-width($tablet) {
    margin-bottom: 15px;
  }
}

h3 {
  font-size: 55px;
  line-height: 30px;
  margin-bottom: 15px;
  font-weight: 400;
  color: $color-4;
  text-shadow: 2px 2px 6px $color-7;
}

h4 {
  font: 30px/37px "matryoshka", Arial, sans-serif;
  margin: 30px 0 0 0;
  color: $color-2;
  font-weight: 400;
}

p,
ul {
  font-family: "roboto", Arial, sans-serif;
  margin-bottom: 30px;
}

a[href] {
  font: 16px "roboto", Arial, sans-serif;
  transition: color 0.3s ease-out;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
    color: $color-2;

    span {
      transition: color 0.3s ease-out;
      color: $color-2 !important;
    }
  }
}

small a[href] {
  font: 12px "roboto", Arial, sans-serif;
  text-decoration: none !important;
}

.two-columns {
  column-count: 1;
  row-gap: 2.5em;

  @media screen and (min-width: $tablet) {
    column-count: 2;
    column-gap: 5em;
    row-gap: 5em;
  }
}

@import "tiles";
@import "forms";

.top-nav {
  @include max-width($tablet) {
    display: flex;
    flex-direction: column;
  }

  a[href] {
    margin-right: 40px;

    @include max-width($tablet) {
      margin-right: 0;
      text-align: right;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}

.bottom-nav,
.top-nav {
  a[href] {
    font-size: 16px;
    font-weight: 500;
    transition: all 0.3s ease-out;
    text-decoration: none !important;

    &:hover {
      color: $color-2;
      text-decoration: none;
    }

    &:focus {
      outline: none;
      color: $color-2;
    }
  }
}

.container {
  padding: 0;
  margin: 0 0 5em 0;
  break-inside: avoid;
  page-break-inside: avoid;
  max-width: none;

  @include max-width($tablet) {
    margin-bottom: 2.5em;
  }

  .text-break & {
    margin-bottom: 0;
    break-inside: auto;
  }

  p {
    column-count: 1;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.text-break {
  margin-bottom: 5em;

  @include max-width($tablet) {
    margin-bottom: 2.5em;
  }
}

.content__header {
  margin: -40px -60px 0 -60px;

  @include max-width($tablet) {
    margin: -40px 0 0 0;
  }

  h1 {
    position: relative;
    padding: 0 60px;
    margin-top: 20px;

    @include max-width($tablet) {
      margin-top: 20px;
      padding: 0;
    }
  }

  img {
    width: 100%;

    + h1 {
      margin-top: 0;

      @include max-width($tablet) {
        margin-top: 20px;
      }
    }

    @include max-width($tablet) {
      display: none;
    }
  }
}

.content__symbol {
  display: none;

  @include max-width($tablet) {
    display: block;
    position: relative;
    width: 100%;
    height: 60px;

    svg {
      position: absolute;
      left: -120px;
      top: -185px;
      width: 300px;
    }
  }
}

.link__arrow {
  > a[href] {
    display: flex;
    gap: 0;
    flex-direction: row;
    align-items: center;
    font-weight: 500;
    text-decoration: none !important;
    transition: color 0.3s ease-out;
    position: relative;

    &:before {
      content: "";
      background: url("../images/link_arrow.svg") center center no-repeat;
      background-size: 100% auto;
      display: inline-block;
      width: 76px;
      height: 38px;
      margin-right: 10px;
    }

    &:focus {
      outline: none;
      color: $color-2;
    }
  }

  &--down {
    > a[href] {
      display: flex;
      gap: 1em;
      flex-direction: row;
      align-items: center;
      font-weight: 500;
      text-decoration: none !important;
      transition: color 0.3s ease-out;

      &:before {
        transform: rotate(180deg);
        content: "";
        background: url("../images/IMG_Pfeil_Klein-1.svg") center center
          no-repeat;
        background-size: 100% auto;
        display: inline-block;
        width: 20px;
        height: 18px;
      }

      &:focus {
        outline: none;
        color: $color-2;
      }
    }
  }
}

.content {
  //overflow: hidden;
  padding: 40px 60px 100px 60px;
  position: relative;
  min-height: 100%;
  transition: opacity 0.3s ease-out;
  opacity: 1;
  margin: $header-height 50px 0 50px;

  a[href] {
    text-decoration: underline;
  }

  &:before {
    content: "";
    position: absolute;
    top: calc(100vh / 1.25);
    left: 50%;
    background: url("../images/arrow_down_black.svg") no-repeat;
    display: inline-block;
    width: 38px;
    height: 76px;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s ease-out;
  }

  &.scroll-arrow:before {
    opacity: 1;
  }

  .home & {
    opacity: 0;
    z-index: -1;
  }

  @include max-width($tablet) {
    padding: 40px 20px 100px 20px;
    position: relative;
    opacity: 1;
    transition: opacity 1s ease-out;
    margin: $header-height 0 0 0;

    &:before {
      display: none;
    }
  }
}

b,
strong {
  font-weight: 500;
}

.flex-row {
  display: flex;
  gap: 1em;
}
