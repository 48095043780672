.pieStatsGrid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-top: 100px;

  .description {
    margin-left: 150px;
    font-family: "Roboto";
    font-style: italic;
    margin-top: 2em;
    line-height: 20px;
    color: var(--secondary);
  }

  .textStatistics {
    align-content: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: self-end;
    font-size: 15px;
    margin-left: 150px;
    font-family: "Roboto";
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 5px;
    align-items: center;
    align-self: baseline;

    > .text {
      margin-right: 10px;
    }

    > .value {
      justify-self: end;
    }

    > .colorBox {
      height: 20px;
      width: 20px;
    }
  }
}

.recharts-legend-item-text {
  color: rgb(38, 39, 36) !important;
}
