@import "vars";

.calendar {
  font: 15px "roboto", Arial, sans-serif;
  background: $color-2;
  padding: 20px 50px;
  min-width: 470px;
  
  @include max-width($tablet) {
    min-width: auto !important;
    padding: 10px;
  }

  &--sheet {
    background: none;
    padding: 0;
    min-width: 370px;
    position: relative;
    
    @include max-width($tablet) {
      min-width: auto;
    }
  }

  [class*="grid-"] > div {
    grid-column: auto !important;
  }

  .calendar__days {
    background: $color-4;
    grid-gap: 10px;
    padding: 0 20px;
  }
  
  .calender__weekday {
    @include max-width($tablet) {
      min-width: auto !important;
    }
  }

  .calendar__weekdays {
    background: $color-4;
    grid-gap: 10px;
    text-align: center;
    padding: 10px 20px;
  }

  .calendar__day {
    display: flex;
    background: $color-6;
    border: 2px solid $color-6;
    justify-content: center;
    align-items: center;
    transition: color .3s ease-out;
    cursor: pointer;
  
    @include max-width($tablet) {
      min-width: auto !important;
    }

    &:before {
      content: '';
      display: inline-block;
      padding-top: 100%;
    }

    &--bookable {
      background: $color-4;
      border-color: $color-1;
      opacity: 1;
    }

    &--inactive {
      //border-color: $color-6;
      opacity: .2 !important;
    }

    &--today {
      background: $color-1;
      color: $color-4;
      opacity: 1;
      border-color: $color-1
    }

    &--booked {
      border-color: $color-2;
      opacity: 1;
    }

    &--selected {
      background: $color-2;
      border-color: $color-2;
      color: $color-1;
      opacity: 1;
    }
  }

  .calendar__month {
    display: flex;
    align-self: center;
    text-align: center;
    padding-bottom: 20px;
    font-size: 20px;

    > * {
      flex: 1 1 auto;
    }

    span {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .calendar__prev, .calendar__next {
    appearance: none;
    background: none;
    border: none;
  }

  .calendar__prev {
    text-align: left;

    svg {
      stroke: $color-1;
      transform: rotate(180deg);
    }
  }

  .calendar__next {
    text-align: right;

    svg {
      stroke: $color-1;
    }
  }

  .calendar__slots {
    padding: 30px 20px 20px 20px;
    background: $color-4;
  }

  .calendar__slot {
    display: flex;
    background: $color-6;
    border: 2px solid $color-6;
    justify-content: center;
    align-items: center;
    opacity: .2;
    transition: color .3s ease-out;
  
    @include max-width($tablet) {
      min-width: auto !important;
    }

    &:before {
      content: '';
      display: inline-block;
      padding-top: 33.3%;
    }

    &--bookable {
      background: $color-4;
      border-color: $color-1;
      opacity: 1;
    }

    &--inactive {
      opacity: .2;
    }

    &--selected {
      background: $color-2;
      border-color: $color-2;
      opacity: 1;
    }
  }

  .calendar__button {
    padding: 20px 20px 0 20px;
    text-align: center;

    button {
      background: $color-2;
    }
  }

  .calendar__loader.lds {
    position: absolute;
    background: rgba($color-4, 0.75);
  }
}

.calender__overlay {
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 1;

  &:after {
    content: "";
    position: absolute;
    top: -10px;
    right: 35%;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7.5px 10px 7.5px;
    border-color: transparent transparent $color-2 transparent;
  }
}
