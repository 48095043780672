@import "vars";

.calendar-admin {
  font: 15px "roboto", Arial, sans-serif;

  .table__header {
    small {
      display: block;
      font: 15px "roboto", Arial, sans-serif;
    }
  }

  .table__item {
    margin-bottom: 20px;

    p {
      margin: 0;
      padding: 0 20px;
    }

    &--entry {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &--form {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      > * {
        flex: 0 1 auto;
        margin: 0 0 10px 10px;
        width: calc(50% - 10px);

        &:first-child {
          width: 100%;
        }
      }
    }
  }
}