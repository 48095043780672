@import "vars";

.calendar-list {
  font: 15px "roboto", Arial, sans-serif;

  .calendar-list__grid {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
    position: relative;
    
    @include max-width($tablet) {
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    }

    .calendar__slots {
      margin: 20px;
    }

    .calendar__slot {
      display: flex;
      background: $color-6;
      border: 2px solid $color-6;
      justify-content: center;
      align-items: center;
      opacity: .2;
      transition: color .3s ease-out;

      &:before {
        content: '';
        display: inline-block;
        padding-top: 33.3%;
      }

      &--bookable {
        background: $color-4;
        border-color: $color-1;
        opacity: 1;
      }

      &--booked {
        opacity: .2;
      }

      &--selected {
        background: $color-2;
        border-color: $color-2;
        opacity: 1;
      }
    }

    &--next, &--prev {
      position: absolute;
      right: -35px;
      top: 50%;
      transform: translateY(-50%);
      appearance: none;
      border: none;
      background: transparent;
      z-index: 1;
      
      @include max-width($tablet) {
        position: absolute;
        left: auto;
        right: 20px;
        top: -10px;
        transform: translate(0);
      }

      svg {
        stroke: $color-2;
      }
    }

    &--prev {
      left: -35px;
      right: auto;
      transform: rotate(180deg);
  
      @include max-width($tablet) {
        left: 20px;
        right: auto;
      }
    }
  }

  .calendar-list__legend {
    display: flex;
    justify-content: center;
    margin: 30px 0;
    position: relative;
    
    @include max-width($tablet) {
      flex-direction: column;
    }

    .link__arrow {
      position: absolute;
      left: 0;
      top: 0;
      
      @include max-width($tablet) {
        left: 30px;
        bottom: -30px;
        top: auto;
      }
    }

    span {
      margin-left: 30px;
      display: flex;
      align-items: center;
  
      @include max-width($tablet) {
        margin-bottom: 30px;
      }

      &:before {
        content: '';
        width: 1.5em;
        height: 1.5em;
        border: 2px solid transparent;
        display: inline-block;
        margin-right: 10px;
      }
    }

    span:nth-child(1):before {
      border-color: $color-2;
    }

    span:nth-child(2):before {
      border-color: $color-1;
    }

    span:nth-child(3):before {
      background: $color-3;
      border-color: $color-3;
    }

    &--slots {
      span:nth-child(1):before {
        border-color: $color-2;
        border-radius: 100%;
      }

      span:nth-child(2):before {
        background: $color-2;
        border-color: $color-2;
        border-radius: 100%;
      }
    }
  }

  .calendar-list__slots {
    text-align: center;
    border-top: 3px solid $color-6;
    padding-top: 30px;
    margin-top: 30px;
  
    .grid-3 {
      @include max-width($tablet) {
        grid-template-columns: repeat(1, 1fr);
      }
    }

    p {
      font-weight: bold;
      font-size: 18px;
    }

    .calendar-list__slot {
      color: $color-6;
      padding: 0 15px;

      &--bookable {
        color: $color-1;
      }

      &--booked {
        color: $color-1;
      }
    }
  }

  .calendar-list__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 30px 0;
    position: relative;
  
    @include max-width($tablet) {
      flex-direction: column;
    }

    .form-input {
      margin-bottom: 30px;
      width: 90%;
    }
    
    button {
      @include max-width($tablet) {
        margin: 0 0 20px 0;
      }
    }

    button + button {
      margin-left: 15px;
  
      @include max-width($tablet) {
        margin-left: 0;
      }
    }
  }
}