@import "vars";

.intern_statistics {
  &--header {
    .overlay {
      left: -2px;
      top: 150px;
      right: auto;
      bottom: auto;

      &:after {
        border-width: 0 7.5px 10px 7.5px;
        border-color: transparent transparent $color-2 transparent;
        bottom: auto;
        top: -10px;
        right: auto;
        left: 80px;
      }
    }
  }

  &--month {
    &--next,
    &--prev {
      position: absolute;
      right: 0;
      appearance: none;
      border: none;
      background: transparent;
      z-index: 1;
      top: 0;

      @include max-width($laptop) {
        right: -20px;
      }

      @include max-width($tablet) {
        right: 10px;
      }

      svg {
        stroke: $color-2;
      }
    }

    &--prev {
      right: auto;
      left: 0;
      top: -2px;
      transform: rotate(180deg);

      @include max-width($laptop) {
        left: -20px;
      }

      @include max-width($tablet) {
        left: 10px;
      }
    }
  }

  &--choose {
    grid-gap: 150px;
    margin-top: 25px;

    @include max-width($laptop) {
      grid-gap: 50px;
    }

    @include max-width($tablet) {
      grid-template-columns: auto;
      grid-gap: 10px;
    }

    &_middle {
      position: relative;
      text-align: center;

      h2 {
        text-transform: none;
        font-weight: 300;
        font-family: "roboto", Arial, sans-serif;
        font-size: 24px;
      }
    }
  }

  &--charts {
    margin-top: 40px;
    margin-left: 60px;

    &_workload,
    &_groupstrength,
    &_students {
      width: 100%;
      height: 600px;
      border-left: 1px solid $color-1;
      border-bottom: 1px solid $color-1;
      position: relative;

      .y-helper {
        position: absolute;
        bottom: 0;
        height: 100%;
        width: 100%;

        .p-33,
        .p-66,
        .p-100 {
          position: absolute;
          left: 0;
          right: 0;
          height: 1px;
          background: $color-3;
          width: 100%;
          bottom: 0;
        }

        .p-33 {
          bottom: calc(33% + 2px);
        }

        .p-66 {
          bottom: calc(66% + 4px);
        }

        .p-100 {
          bottom: 100%;
        }
      }

      .y-legend {
        width: 30px;
        position: relative;
        height: 100%;
        left: -43px;

        > div {
          position: absolute;
          right: 0;
          font-family: "roboto", Arial, sans-serif;
          font-size: 14px;
          line-height: 14px;
          text-indent: -9999px;

          &:after {
            position: absolute;
            right: -12px;
            width: 6px;
            height: 1px;
            background: $color-1;
            content: "";
            top: 50%;
          }
        }
      }

      .y-helper {
        .average {
          position: absolute;
          left: -50px;
          right: 0;
          height: 1px;
          background: $color-7;
          width: calc(100% + 50px);

          &:before {
            content: attr(data-content);
            right: calc(100% + 5px);
            top: -6px;
            position: absolute;
            font-family: "roboto", Arial, sans-serif;
            font-size: 12px;
            line-height: 12px;
            font-weight: 600;
            width: 60px;
            text-align: right;
            color: $color-7;
          }

          &--current {
            background: repeating-linear-gradient(
              to right,
              $color-3,
              $color-3 10px,
              $color-4 10px,
              $color-4 20px
            );

            &:before {
              color: $color-3;
            }
          }
        }
      }

      .x-legend {
        > div {
          position: absolute;
          bottom: -20px;
          font-family: "roboto", Arial, sans-serif;
          font-size: 14px;
          line-height: 14px;
        }
      }
    }

    &_workload {
      .y-legend {
        > div {
          text-indent: unset;
        }

        .p-0 {
          bottom: -7px;

          &:before {
            display: none;
          }
        }

        .p-33 {
          bottom: calc(33% - 4px);
        }

        .p-66 {
          bottom: calc(66% - 2px);
        }

        .p-100 {
          bottom: calc(100% - 6px);
        }
      }
    }

    &_month {
      &--workload,
      &--groupstrength,
      &--students {
        .x-legend {
          @for $i from 1 through 31 {
            .day-#{$i} {
              left: calc((3% + 0.2%) * #{$i});
            }
          }
        }

        .x-values {
          @for $i from 1 through 31 {
            .dayvalue-#{$i} {
              left: calc((3% + 0.2%) * #{$i} + 3px);
            }
          }

          .dayvalue {
            position: absolute;
            width: 10px;
            background: $color-2;
            bottom: 0;

            &--grey {
              background: $color-3;
            }
          }
        }
      }
    }

    &_year {
      &--workload,
      &--groupstrength,
      &--students {
        .x-legend {
          @for $i from 1 through 12 {
            .month-#{$i} {
              left: calc((8% + 0.2%) * #{$i});
            }
          }
        }

        .x-values {
          @for $i from 1 through 12 {
            .monthvalue-#{$i} {
              left: calc((8% + 0.2%) * #{$i});
            }
          }

          .monthvalue {
            position: absolute;
            width: 10px;
            background: $color-2;
            bottom: 0;

            &--grey {
              background: $color-3;
            }
          }
        }
      }
    }

    &_groupstrength {
      .y-legend {
        > div {
          &:nth-child(4n + 1) {
            text-indent: unset;
          }
        }

        .ps-0 {
          bottom: -7px;

          &:before {
            display: none;
          }
        }

        @for $i from 1 through 32 {
          .ps-#{$i} {
            bottom: calc(3.125% * #{$i} - 6px);
          }
        }
      }

      .y-helper {
        @for $i from 1 through 32 {
          .ps-#{$i} {
            position: absolute;
            left: 0;
            right: 0;
            height: 1px;
            background: $color-3;
            width: 100%;
            bottom: calc(3.125% * #{$i} - 0px);
          }
        }
      }
    }

    &_students {
      .y-legend {
        > div {
          display: none;

          &:nth-child(4n + 1) {
            display: none;
          }

          &:nth-child(8n + 1) {
            text-indent: unset;
            display: block;
          }

          &:after {
            position: absolute;
            right: -12px;
            width: 6px;
            height: 1px;
            background: $color-1;
            content: "";
            top: 50%;
          }
        }

        .ps-0 {
          bottom: -7px;

          &:before {
            display: none;
          }
        }

        @for $i from 1 through 96 {
          .ps-#{$i} {
            bottom: calc(1.03% * #{$i} - 6px);
          }
        }
      }

      .y-helper {
        @for $i from 1 through 96 {
          .ps-#{$i} {
            position: absolute;
            left: 0;
            right: 0;
            height: 1px;
            background: $color-3;
            width: 100%;
            bottom: calc(1.03% * #{$i} - 0px);
            display: none;

            &:nth-child(4n + 1) {
              display: none;
            }

            &:nth-child(8n + 1) {
              text-indent: unset;
              display: block;
            }
          }
        }
      }
    }

    &_year {
      &--students {
        .y-legend {
          @for $i from 1 through 2112 {
            .ps-#{$i} {
              bottom: calc((100% / 2212) * #{$i} - 6px);
            }
          }

          > div {
            display: none;

            &:nth-child(4n + 1) {
              display: none;
            }

            &:nth-child(8n + 1) {
              text-indent: -9999px;
              display: none;
            }

            &:nth-child(176n + 1) {
              text-indent: unset;
              display: block;
            }
          }
        }

        .y-helper {
          @for $i from 1 through 2112 {
            .ps-#{$i} {
              position: absolute;
              left: 0;
              right: 0;
              height: 1px;
              background: $color-3;
              width: 100%;
              bottom: calc((100% / 2212) * #{$i} - 0px);
              display: none;

              &:nth-child(4n + 1) {
                display: none;
              }

              &:nth-child(8n + 1) {
                text-indent: -9999px;
                display: none;
              }

              &:nth-child(176n + 1) {
                text-indent: unset;
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
