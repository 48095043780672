.draggable {
  cursor: move;
  user-select: none;
  opacity: 100%;
}

.dragging {
  opacity: 50%;
}

.droppable {
  &:not(.dragging) {
    background: var(--secondary);
  }
}

.customDragged {
  border-radius: 5px;
  border: 1px solid grey;
  font-family: "Roboto";
  font-size: 16px;
  max-width: max-content;
  min-width: 150px;
  padding: 0.5em 1em;
  background: white;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1em;
  align-items: center;

  .bwt {
    font-weight: bold;
  }
}
