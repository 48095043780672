@import "vars";

.register {
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-4;

    > section {
      max-width: 650px;
    }

    &:before {
      display: none;
    }
  }
}