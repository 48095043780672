@import "vars";

.location {
  .tile:nth-child(3) {
    box-shadow: 0 0 15px $color-1;
    clip-path: inset(0 0 -15px 0);
    z-index: 1;

    a[href] {
      span {
        display: none;
      }

      svg {
        display: block;
        max-width: 300%;
        transform: translate(-50%, -42%);
      }
    }
  }

  .content {
    background: $color-3;
    color: $color-5;

    @include max-width($tablet) {
      .content__symbol svg {
        left: -110px;
        top: -180px;
      }
    }

    &:before {
      display: none;
    }
  }
}