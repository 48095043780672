@import "vars";

[class*="intern_"] .table.table--daily {

  .timeslot {
    font: 600 18px/26px Arial, sans-serif;
    margin-bottom: 10px;
    text-align: center;
  }

  .time {
    font: 400 14px/18px Arial, sans-serif;
    text-align: center;

    &--done {
      color: $color-3;
    }

    p {
      margin: 0;
    }
  }
  
  .link__arrow {
    > a[href] {
      display: inline-block;
      padding-left: 40px;
      
      &:before {
        position: absolute;
        left: -5px;
      }
    }
  }

  .school_name {
    font: 600 18px/26px Arial, sans-serif;
    white-space: normal;
  }

  .class_name {
    font: 400 16px/18px Arial, sans-serif;
  }

  .no_booking {
    color: $color-3;
    font-style: italic;
    padding-left: 50px;
  }

  .state {
    position: relative;

    .circle {
      border-radius: 50%;
      width: 30px;
      height: 30px;
      margin: 0 auto 10px auto;
      border: 2px solid $color-3;
      background: $color-4;
      z-index: 3;
      position: relative;
    }

    &--open {
      .circle {
        background: $color-4;
      }
    }

    &--done {
      .circle {
        background: $color-3;
      }
    }

    &--active {
      .circle {
        background: $color-2;
        border-color: $color-2;
      }
    }
  }

  .table__row {
    border: none;
    padding: 30px 0;
  }
  
  .table__row,
  .table__header {
    > div:first-of-type {
      min-width: 300px;
    }
  }

  .table__col--timeline {
    position: relative;

    &:before, &:after {
      content: '';
      display: block;
      position: absolute;
      left: -15px;
      right: -15px;
      top: 14px;
      border: 1px dashed $color-6;
      height: 0;
      z-index: 1;
    }

    &:after {
      border: 1px solid $color-3;
      z-index: 2;
      display: none;
    }

    &-start {
      &:before, &:after {
        left: 50%;
      }

      &.table__col--timeline-active {
        &:after {
          left: 50% !important;
        }
      }
    }

    &-end {
      &:before, &:after {
        right: 50%;
      }
    }

    &-done {
      &:after {
        display: block;
      }
    }

    &-active {
      &:after {
        display: block;
        left: -15px;
        right: 50%;
      }
    }
  }

  .showroom {
    position: relative;

    .table__col--timeline {
      position: static;
    }
  }
}