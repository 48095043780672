// colors
$color-1: #000000; // black
$color-2: #FEE354; // yellow
$color-3: #CBCAC4; // gray
$color-4: #FFFFFF; // white
$color-5: #262724; // dark gray
$color-6: #D5D4CF; // light grey
$color-7: #666666; // middle grey

$color-10: rgb(39, 125, 206); // blue
$color-11: rgb(186, 63, 160); // magenta
$color-12: rgb(244, 147, 31); // orange
$color-13: rgb(63, 169, 109); // green

// dimensions
$header-height: 60px;
$max-width: 1440px;

// viewport widths
$mobile: 320px;
$tablet: 768px;
$laptop: 1024px;
$desktop: 1440px;

// mixins
@mixin min-width($viewPortWidth) {
  @media only screen and (min-width: $viewPortWidth) {
    @content
  }
}

@mixin max-width($viewPortWidth) {
  @media only screen and (max-width: $viewPortWidth - 1) {
    @content
  }
}
