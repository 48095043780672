@import "vars";

.imprint {
  article {
    background: url("../images/background_2.svg") center top -150px no-repeat $color-4;
    background-size: 500px auto;
  
    @include max-width($tablet) {
      background-size: 100%;
    }
  
    &.scroll-arrow:before {
      opacity: 0;
    }
  }
}