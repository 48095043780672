@import "vars";

.sortable {
  display: flex;
  flex-direction: row;
  align-items: center;

  &:after {
    content: '';
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid $color-1;
    margin-left: 10px;
    transition: transform .3s ease-out;
    transform-origin: 50% 50%;
    
    @include max-width($tablet) {
      display: none;
    }
  }

  &.sort-asc:after {
    transform: rotate(180deg);
  }

  &.sort-desc:after {
    transform: rotate(0deg);
  }
}

