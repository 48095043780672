@import "vars";

.intern_add_class {
	@include max-width($tablet) {
		padding: 0 20px;
	}
	
	.grid-2 {
		@include max-width($tablet) {
			grid-template-columns: repeat(1, 1fr) !important;
			display: block;
		}
	}
}