@import "vars";

.press .content {
  background: $color-4;
  color: $color-5;
  
  &.scroll-arrow:before {
    opacity: 0;
  }

  ul {
    display: flex;

    li {
      flex: 1 1 33.3%;

      a {
        display: block;
      }
    }
  }
}