@import "vars";

.data_protection {
  article {
    background: url("../images/background_1.svg") center top -200px no-repeat $color-4;
    background-size: 500px auto;
  
    @include max-width($tablet) {
      background-size: 100%;
    }
  }
}