@import "vars";

[class*="intern_"] {
  .logo {
    flex: 0 1 auto;
  }

  .greeting {
    font-family: "roboto", Arial, sans-serif;
    font-weight: 600;
    flex: 1 1 auto;
    text-align: center;

    @include max-width($tablet) {
      display: none;
    }
  }

  .content {
    padding: 40px 0;

    &:before {
      display: none;
    }
  }

  .top-nav,
  .bottom-nav {
    display: none;
  }

  .tiles .tile {
    width: 0;
  }

  .tabs {
    display: flex;
    justify-content: center;
    font: 18px/26px "roboto", Arial, sans-serif;
    margin-bottom: 30px;

    @include max-width($tablet) {
      flex-direction: column;
    }

    .tab {
      flex: 0 1 auto;
      appearance: none;
      border: none;
      background: none;
      padding: 5px 20px;
      border-bottom: 2px solid transparent;
      font-weight: 500;
      margin: 0 20px;
      transition: border-bottom 0.3s ease-out;

      &--active {
        border-color: $color-2;
      }
    }
  }

  .table {
    margin-bottom: 0;
    font: 15px "roboto", Arial, sans-serif;

    .table__header {
      font: 28px/37px "matryoshka", Arial, sans-serif;
      padding: 30px 0 15px;
      position: relative;

      @include max-width($tablet) {
        font: 18px/22px "matryoshka", Arial, sans-serif;
      }

      &--extra {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .table__content {
      max-height: 100vh;
      overflow-y: auto;
      min-height: 300px;

      @include max-width($tablet) {
        max-height: none;
      }
    }

    .table__row {
      font: 15px/26px "roboto", Arial, sans-serif;
      position: relative;
      border-bottom: 1px solid $color-6;
      padding: 15px 0;
      align-items: center;

      &--extra {
        display: flex;
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);

        .delete {
          position: relative;

          .overlay {
            right: 0;
            left: auto;
            top: 50px;
            bottom: auto;

            &:after {
              top: -10px;
              bottom: auto;
              right: 7px;
              left: auto;
              border-width: 0 7.5px 10px 7.5px;
              border-color: transparent transparent $color-2 transparent;
            }

            > p {
              margin: 0 0 10px 0;
            }
          }
        }
      }

      .status {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .table__col--booking {
      position: relative;

      &:hover {
        cursor: pointer;
      }

      &.no-hover {
        cursor: default;
      }
    }

    .table__footer {
      font: 15px "roboto", Arial, sans-serif;
      text-align: center;
      margin-top: 30px;
    }

    .table__legend {
      position: absolute;
      bottom: 30px;
      right: 0;
      display: flex;
      flex-direction: row;

      span {
        padding-left: 20px;
        margin-left: 10px;
      }
    }

    .complete,
    .incomplete {
      display: flex;
      flex-direction: row;
      align-items: center;

      &:before {
        content: "";
        width: 15px;
        height: 15px;
        border: 2px solid $color-2;
        display: inline-block;
        border-radius: 100%;
        margin-right: 5px;
      }
    }

    .complete:before {
      background: $color-2;
    }

    &--classes {
      padding: 0 30px 60px;

      [class*="grid-"] {
        > div:first-child {
          grid-column: 1 / 3;

          @include max-width($tablet) {
            grid-column: 1;
            min-width: 80px;
          }
        }
      }

      .table__header,
      .table__content {
        .class__size {
          @include max-width($tablet) {
            display: none;
          }
        }
      }

      .grid-5 {
        @include max-width($tablet) {
          grid-template-columns: repeat(4, 1fr);
          font: 12px/16px "roboto", Arial, sans-serif;
        }

        .link__arrow {
          a[href] {
            white-space: normal;

            &:before {
              @include max-width($tablet) {
                display: none;
              }
            }
          }
        }
      }

      .calender__overlay {
        @include max-width($tablet) {
          right: -40px;
        }

        &:after {
          @include max-width($tablet) {
            right: 23%;
          }
        }
      }
    }

    .showroom {
      &:not(.headline) {
        display: flex;
        justify-content: space-around;
      }
    }
  }

  h1 {
    text-transform: none;
    margin-bottom: 0;
  }

  h2 {
    font-size: 38px;
  }

  .downloads {
    display: flex;
    flex-direction: column;
    justify-self: right;
  }

  .link__arrow {
    > a[href] {
      gap: 0 !important;

      &:before {
        margin-right: 10px !important;
        width: 38px;
      }
    }
  }

  .close {
    transition: color 0.3s ease-out;
  }

  .info-text {
    background: $color-2;
    border: 2px solid $color-2;
    padding: 3px;
  }

  .clean-button {
    appearance: none;
    border: none;
    background: none;
    outline: none;
  }

  .teacher__abbr {
    &--content {
      margin-right: 10px;
      border-radius: 50%;
      border: 2px solid $color-2;
      color: $color-1;
      font-weight: 400;
      font-size: 22px;
      line-height: 21px;
      font-family: "matryoshka", Arial, sans-serif;
      width: 40px;
      height: 40px;
      align-items: center;
      justify-content: center;
      display: flex;

      @include max-width($tablet) {
        flex: 1 0 40px;
      }
    }

    &--container {
      display: flex;
      align-items: center;
    }
  }

  .hint {
    position: relative;
    bottom: auto;

    &:after {
      content: "";
      position: absolute;
      top: -10px;
      left: 10%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 7.5px 10px 7.5px;
      border-color: transparent transparent $color-2 transparent;
    }
  }
}

.overlay {
  background: $color-2;
  color: $color-1;
  position: absolute;
  right: 0;
  bottom: 50px;
  z-index: 1;
  padding: 20px 40px;
  min-width: 400px;

  button {
    background: $color-2;
  }

  svg * {
    stroke: $color-1;
  }

  &:after {
    content: "";
    position: absolute;
    bottom: -10px;
    right: 80px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 7.5px 0 7.5px;
    border-color: $color-2 transparent transparent transparent;
  }
}

// grid

[class*="grid-"] {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 30px;
}

.grid-1 {
  grid-template-columns: repeat(1, 1fr);
}

.grid-2 {
  grid-template-columns: repeat(2, 1fr);
}

.grid-3 {
  grid-template-columns: repeat(3, 1fr);
}

.grid-4 {
  grid-template-columns: repeat(4, 1fr);
}

.grid-5 {
  grid-template-columns: repeat(5, 1fr);
}

.grid-6 {
  grid-template-columns: repeat(6, 1fr);
}

.grid-7 {
  grid-template-columns: repeat(7, 1fr);
}

.grid-8 {
  grid-template-columns: repeat(8, 1fr);
}
