@import "vars";

.thumb_slider {
  .splide {
    .splide__slide {
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .splide + .splide {
    margin-top: 1rem;
  }
}

