.description {
  font-family: "Roboto";
  font-style: italic;
  margin-top: 2em;
  line-height: 20px;
  color: var(--secondary);
  display: flex;
  margin-left: 200px;
  margin-right: 200px;
  justify-content: center;
}
