@import "vars";

@mixin info() {
  position: relative;

  &.incomplete:after {
    content: '!';
    display: flex;
    position: absolute;
    top: -5px;
    right: -5px;
    background: $color-2;
    border-radius: 50% 50%;
    width: 15px;
    height: 15px;
    justify-content: center;
    align-items: center;
    color: $color-1;
  }
}

.intern_profile {
  .content {
    @include max-width($tablet) {
      padding: 40px 20px 100px 20px;
    }
  }

  h1 {
    margin-bottom: 50px;

    @include max-width($tablet) {
      margin-bottom: 20px;
    }
  }

  h2 {
    margin-bottom: 10px;

    @include max-width($tablet) {
      font-size: 28px;
    }
  }

  &--container {
    width: 100%;
    display: block;
    float: left;
    margin-bottom: 50px;

    .left,
    .right {
      width: 50%;
      float: left;

      @include max-width($tablet) {
        width: 100%;
      }
    }

    .left {
      padding-right: 160px;

      @include max-width($tablet) {
        padding-right: 0;
      }
    }

    .right {
      padding-left: 100px;

      @include max-width($tablet) {
        padding-left: 0;
      }
    }

    input.empty {
      border: 2px solid $color-2;
    }
  }

  .overlay_not_equal {
    left: 0;
    margin: 0;
    top: 50px;
    bottom: auto;
    right: auto;

    &:after {
      content: "";
      position: absolute;
      top: -10px;
      left: 10%;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 7.5px 10px 7.5px;
      border-color: transparent transparent $color-2 transparent;
    }

    p {
      margin: 0;
    }
  }

  .overlay_not_valid {
    left: 0;
    margin: 0;
    top: 50px;
    right: auto;
    bottom: auto;

    &:after {
      left: 10%;
      top: -10px;
      bottom: auto;
      border-width: 0 7.5px 10px 7.5px;
      border-color: transparent transparent $color-2 transparent;
    }

    p {
      margin: 0;
    }
  }

  .school {

    &-container {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-delete {
      padding-right: 50px;
    }

    &--delete-overlay {
      position: relative;

      .overlay {
        bottom: auto;
        top: 40px;
        left: auto;
        right: -35px;
        width: 400px;
        text-align: center;

        &:after {
          content: "";
          position: absolute;
          top: -10px;
          right: 10%;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 7.5px 10px 7.5px;
          border-color: transparent transparent $color-2 transparent;
        }

        p {
          margin-bottom: 10px;
        }
      }
    }

    &-add {
      margin-top: 50px;
      display: inline-block;
      width: 75%;

      &-button {
        right: -50px !important;
        height: 50px !important;

        svg {
          width: 40px;
          height: 40px;
        }
      }
    }

    &.confirmed {
      position: relative;

      &:before {
        position: absolute;
        content: '';
        background: url('../images/IMG_Check.svg') no-repeat;
        width: 17px;
        height: 19px;
        left: -20px;
        top: 50%;
        margin-top: -6px
      }
    }
  }
}

.account {
  @include info;
  font-family: "roboto", Arial, sans-serif;
  font-weight: 600;
  flex: 0 1 auto;

  &:hover {
    cursor: pointer;
  }

  &-popup {
    position: absolute;
    width: 200px;
    right: 2px;
    top: calc(100% + 2px);
    z-index: 2;
    background: $color-1;
    padding: 15px;

    ul {
      margin: 0;

      li {
        @include info;
        padding: 5px 20px;

        &.incomplete:after {
          right: auto;
          left: -5px;
          top: 5px;
        }

        &:first-of-type {
          padding-top: 0;
        }

        &:last-of-type {
          margin-top: 10px;
          border-top: 1px solid $color-4;
        }

        a[href] {
          &:hover {
            color: $color-2;
            font-weight: bold;
            text-decoration: none;
          }
        }
      }
    }
  }
}