@import "vars";

.intern_documents {
  .tiles {
    @include max-width($tablet) {
      background: $color-4;
    }
  }
  
  .content {
    @include max-width($tablet) {
      padding: 40px 20px 100px 20px;
    }
  }
  
  h1 {
    margin-bottom: 50px;
  
    @include max-width($tablet) {
      margin-bottom: 20px;
    }
  }
  
  h2 {
    margin-bottom: 0;
  
    @include max-width($tablet) {
      font-size: 28px;
    }
  }
  
  &--container {
    width: 100%;
    display: block;
    float: left;
    margin-bottom: 50px;
    
    .left,
    .right {
      width: 50%;
      float: left;
  
      @include max-width($tablet) {
        width: 100%;
      }
    }
    
    .right {
      padding-left: 150px;
  
      @include max-width($tablet) {
        padding-left: 0;
      }
    }
  }
}