@import "vars";

.about {
  .tile:nth-child(1) {
    box-shadow: 0 0 15px $color-1;
    clip-path: inset(0 0 -15px 0);
    z-index: 1;

    a[href] {
      span {
        display: none;
      }

      svg {
        display: block;
        max-width: 300%;
        transform: translate(-50%, -42%);
      }
    }
  }

  .content {
    background: $color-2;
    color: $color-5;

    .partner-logos {
      display: flex;
      column-gap: 5em;
      margin-top: 30px;

      > li {
        flex: 1 1 33%;

        &:first-child {
          text-align: left;
        }

        &:nth-child(2) {
          text-align: center;
        }

        &:last-child {
          text-align: right;
        }

        @include max-width($tablet) {
          text-align: left !important;
        }

        img {
          max-height: 106px;
        }
      }

      @include max-width($tablet) {
        row-gap: 30px;
        flex-wrap: wrap;
        margin: 0;

        > li {
          flex: 0 1 100%;
        }
      }
    }

    .bottom-nav a[href] {
      &:hover {
        color: $color-3 !important;
      }
    }
  }
}